<template>
  <div>
    <Toast v-if="toastData.show" :type="toastData.type" :message="toastData.message" :show="toastData.show"
      :time="10000" @clearToast="clearToast" />
    <div class="card">
      <div class="d-flex w-100 m-0 justify-content-between table-top">
        <div class="search-wrapper">
          <b-input-group class="">
            <b-input-group-prepend>
              <img src="@/assets/Icons/searchDark.svg" class="image" />
            </b-input-group-prepend>
            <b-form-input placeholder="Search by Campaign Name" v-model="searchedTextValue"
              @keyup="searchCampaign($event)"></b-form-input>
          </b-input-group>
        </div>
        <div>
          {{ startDate }} -
          {{ endDate }}
        </div>
        <date-picker v-model="customDate" class="date-picker" ref="datePicker" format="MMM YYYY" range confirm
          confirm-text="Done"></date-picker>
        <w-white-dropdown :options="options" :placeHolder="'Date'" :selectedOption="selectedFilter" :labelText="''"
          @input="selectFilter($event)" width="178px">
        </w-white-dropdown>
      </div>
      <div class="overflow-auto">
        <b-table id="spaceTable" :items="tableData" :fields="fields" class="mb-0" :busy="tableLoading"
          :total-rows="rows" :per-page="perPage">
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2" style="font-size: large;">Loading...</strong>
            </div>
          </template>
          <template #table-colgroup="scope">
            <col v-for="field in scope.fields" :key="field.key" style="width: 20rem" />
          </template>
          <template #head()="data">
            <div class="column-heading">{{ data.label }}</div>
          </template>
          <template #head(campaignName)="">
            <div class="column-heading" style="width: 320px">Campaign Name</div>
          </template>
          <template #head(campaignOverview)="">
            <div class="column-heading">
              Campaign Overview - KPIs and Channels
            </div>
          </template>

          <template #cell(campaignName)="data">
            <div class="name-wrapper">
              <div class="campagin-name d-none">
                {{ data.item.campaignName }}
              </div>
              <div class="badge-wrapper">
                <b-badge v-for="(item, index) in data.item.products" :key="index" class="mb-2 mr-2 column-data">{{ item
                }}</b-badge>
              </div>
              <h5 class="created-on mt-2">
                Created On:
                <span style="color: #222a37">{{ data.item.createdOn }}</span>
              </h5>
              <div class="d-flex">
                <h5 class="created-on mr-2 mt-2">
                  Start Date:
                  <span style="color: #222a37">{{ data.item.startDate }}</span>
                </h5>
                <h5 class="created-on mt-2">
                  End Date:
                  <span style="color: #222a37">{{ data.item.endDate }}</span>
                </h5>
              </div>
            </div>
          </template>
          <template #cell(campaignOverview)="data">
            <div class="overview-wrapper">
              <div class="d-flex justify-content-center slider-wrapper">
                <div class="slider-btn slider-left" @click="scrollLeft(data.index)" v-if="data.item.stats.length > 3">
                  <i class="fas fa-chevron-left"></i>
                </div>
                <div :ref="`slider-${data.index}`" class="d-flex statistics-wrapper">
                  <div v-for="(stat, index) in data.item.stats" :key="index" style="position: relative" class="statics">
                    <Statistics :data="{ label: stat.name, value: stat.value, icon: '' }" :index="index" :img="
                      stat.name === 'Clicks'
                        ? 'click.svg'
                        : stat.name === 'Cost'
                          ? 'dollar.svg'
                          : 'green-fill.svg'
                    " :specialChar="false" :specialCharText="''" />
                    <div v-if="data.item.stats.length - 1 > index" class="item-right-line"></div>
                  </div>
                </div>
                <div class="slider-btn slider-right" @click="scrollRight(data.index)" v-if="data.item.stats.length > 3">
                  <i class="fas fa-chevron-right"></i>
                </div>
              </div>
              <div class="d-flex justify-content-between" style="padding-top: 6px; padding-right: 6px;">
                <div class="d-flex align-items-center">
                  <span class="channels">Channels ({{ data.item.channels }})</span>
                  <!-- <img
                    class="down-arrow"
                    src="@/assets/down-arrow.svg"
                    alt=""
                  /> -->
                  <!-- <span class="down-arrow"></span> -->
                </div>
                <div class="d-flex justify-content-end">
                  <w-button :buttonText="'Dashboard'" @buttonClicked="goToDashboard(data.item)"></w-button>
                  <!--<button
                    class="btn-effect-outline"
                    @click="$router.push('unified-summer-campaign')"
                  >
                    <span style="float: left; margin-left: 10px"
                      >Dashboard</span
                    >
                    <i
                      class="fas fa-arrow-right"
                      style="float: right; margin-right: 10px"
                    ></i>
                  </button>-->
                </div>
              </div>
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <b-pagination v-if="totalrow !== 0" v-model="currentPage" :per-page="perPage" pills align="right"
      hide-goto-end-buttons hide-ellipsis :total-rows="totalrow" class="mb-5" aria-controls="spaceTable"
      @change="getSelectedPage($event)">
      <template #prev-text><span><img src="@/assets/Icons/arrow-left-line.svg" class="arrow-image" /></span></template>
      <template #next-text><span><img src="@/assets/Icons/arrow-right-line.svg" class="arrow-image" /></span></template>
      <template #page="{ page, active }">
        <span v-if="active">{{ page }}</span>
        <i v-else>{{ page }}</i>
      </template>
    </b-pagination>
    <div v-if="tableData.length === 0 && !tableLoading" class="no-items-wrapper">
      <div class="text-center">
        <img src="@/assets/tech-life-media2.png" alt="" />
        <div v-if="tableData.length === 0 && searchedTextValue === ''" class="no-items-description">
          No Campaign has been created yet
        </div>
        <div v-else-if="searchedTextValue !== ''" class="no-items-description">
          Oops!<br />
          Sorry to say, it seems like no campaign has been found
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Statistics from "@/components/Statistics.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
import DatePicker from "vue2-datepicker";
import "@/assets/style/datepicker/index.css";
import Button from "@/components/Profile/Button.vue";
import { HealthMonitor } from "@/services/HealthMonitorServices.js";
import moment from "moment";
import Toast from "@/components/Toast/Toast.vue";
var timeout;
const healthMonitor = new HealthMonitor();

export default {
  name: "HealthTable",
  props: {},
  components: {
    Statistics,
    "w-white-dropdown": WhiteDropdown,
    DatePicker,
    Toast,
    "w-button": Button,
  },
  watch: {
    filterBy(newVal) {
      this.filterOn = [];
      this.filterOn.push(newVal.id);
    },
    customDate(newVal) {
      if (newVal && newVal.length) {
        this.startDate = moment(newVal[0]).format("YYYY-MM-DD");
        this.endDate = moment(newVal[1]).format("YYYY-MM-DD");
        this.getHealthMonitorList();
      }
    },
  },
  data() {
    return {
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      tableLoading: false,
      rows: null,
      tableData: [],
      selectedPageNo: 1,
      fields: [
        { key: "campaignName", sortable: false },
        { key: "campaignOverview", sortable: false },
      ],
      totalrow: 0,
      perPage: 5,
      currentPage: 1,
      overDelete: false,
      overView: false,
      overShare: false,
      searchedTextValue: "",
      filterOn: [],
      selectedFilter: { text: "Date", id: "" },
      options: [
        { text: "Yesterday", id: "yesterday" },
        { text: "Today", id: "today" },
        { text: "Last 7 Days", id: "Last 7 Days" },
        { text: "Last 30 Days", id: "Last 30 Days" },
        { text: "This Month", id: "This Month" },
        { text: "Last Month", id: "Last Month" },
        { text: "This Year", id: "This Year" },
        { text: "Last Year", id: "Last Year" },
        { text: "Custom", id: "customSelect" },
      ],
      startDate: "",
      endDate: "",
      customDate: [],
      edited: false,
    };
  },
  methods: {
    searchCampaign(e) {
      var self = this;
      clearTimeout(timeout);
      this.searchedTextValue = e.target.value.toLowerCase();
      if (self.searchedTextValue.length !== 0) {
        timeout = setTimeout(function () {
          self.getHealthMonitorList();
          clearTimeout(timeout);
        }, 2000);
      } else {
        self.getHealthMonitorList();
      }
    },
    errorToast(e) {
      if (e === undefined) {
        this.toastData = {
          type: "failed",
          show: true,
          message: "Something went wrong Please try after sometime",
        };
      } else {
        this.toastData = {
          type: "failed",
          show: true,
          message: e,
        };
      }
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    getHealthMonitorList() {
      this.tableLoading = true;
      healthMonitor
        .getHealthMonitorDashboards(
          this.currentPage,
          this.perPage,
          this.startDate,
          this.endDate,
          sessionStorage.getItem("subId"),
          this.$route.meta.type,
          this.searchedTextValue
        )
        .then((res) => {
          this.tableLoading = false;
          this.totalrow = res.totalElements;
          this.tableData = [];
          for (var i = 0; i < res.content.length; i++) {
            if (res.content[i].unifiedCampaigname !== "default") {

              this.tableData.push({
                campaignName: res.content[i].unifiedCampaigname,
                startDate: moment(res.content[i].startDate).format("YYYY-MM-DD"),
                endDate: moment(res.content[i].endDate).format("YYYY-MM-DD"),
                products: [res.content[i].unifiedCampaigname],
                dataStartDate: res.content[i].startDateForCampaign,
                dataEndDate: res.content[i].endDateForCampaign,
                createdOn: res.content[i].createdDate,
                channels: res.content[i].channels.length,
                unifiedCampaignId: res.content[i].unifiedCampaignId,
                stats: [
                  { name: "Clicks", value: res.content[i].clicks },
                  { name: "Conversions", value: res.content[i].conversions },
                  { name: "Cost", value: res.content[i].cost },
                  { name: "Engagements", value: res.content[i].engagements },
                  { name: "Impressions", value: res.content[i].impressions },
                ],
              });
            }
          }
        })
        .catch((e) => {
          this.errorToast(e.message);
          this.tableLoading = false;
        });
    },
    scrollRight(index) {
      this.$refs[`slider-${index}`].scrollLeft += 150;
    },
    scrollLeft(index) {
      this.$refs[`slider-${index}`].scrollLeft -= 150;
    },
    selectFilter(e) {
      if (this.selectedFilter === e) {
        return;
      }
      this.customDate = [];
      this.selectedFilter = e;
      var startDateLocal;
      var endDateLocal;
      if (e && e.id === "customSelect") {
        this.$refs.datePicker.openPopup();
      } else if (e && e.id === "today") {
        startDateLocal = new Date();
        endDateLocal = new Date();
      } else if (e && e.id === "yesterday") {
        let dateObj = new Date();
        dateObj.setDate(dateObj.getDate() - 1);
        startDateLocal = dateObj;
        endDateLocal = dateObj;
      } else if (e && e.id === "Last 7 Days") {
        startDateLocal = new Date(new Date().setDate(new Date().getDate() - 6));
        endDateLocal = new Date();
      } else if (e && e.id === "Last 30 Days") {
        startDateLocal = new Date(
          new Date().setDate(new Date().getDate() - 30)
        );
        endDateLocal = new Date();
      } else if (e && e.id === "This Month") {
        var today = new Date();
        startDateLocal = new Date(today.getFullYear(), today.getMonth() - 0, 1);
        endDateLocal = new Date();
      } else if (e && e.id === "Last Month") {
        today = new Date();
        startDateLocal = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        endDateLocal = new Date(today.getFullYear(), today.getMonth(), 0);
      } else if (e && e.id === "This Year") {
        today = new Date();
        startDateLocal = new Date(today.getFullYear(), 0, 1);
        endDateLocal = today;
      } else if (e && e.id === "Last Year") {
        today = new Date();
        startDateLocal = new Date(new Date().getFullYear(), -11, -30);
        endDateLocal = new Date(new Date().getFullYear(), 0, 0);
      }
      this.startDate = moment(startDateLocal).format("YYYY-MM-DD");
      this.endDate = moment(endDateLocal).format("YYYY-MM-DD");
      this.edited = true;
      sessionStorage.setItem("startDate", this.startDate);
      sessionStorage.setItem("endDate", this.endDate);
      sessionStorage.setItem(
        "selectedDate",
        JSON.stringify(this.selectedFilter)
      );
      if (this.selectedFilter.text === "Custom") {
        return;
      }
      this.getHealthMonitorList();
    },
    getSelectedPage(event) {
      // if (this.searchedTextValue === "") {
      if (event != this.selectedPageNo) {
        this.selectedPageNo = event;
        this.currentPage = event;
        this.perPage = 5;
        this.getHealthMonitorList();
      }
      // } else {
      // this.selectedPageNo = event;
      // this.currentPage = event;
      // this.perPage = 20;
      // this.getSearchedTableData(this.searchedTextValue);
      // }
    },
    goToDashboard(plan) {
      if (!this.edited) {
        sessionStorage.setItem("startDate", plan.startDate);
        sessionStorage.setItem("endDate", plan.endDate);
      }
      sessionStorage.setItem("dataStartDate", plan.dataStartDate);
      sessionStorage.setItem("dataEndDate", plan.dataEndDate);
      sessionStorage.setItem(
        "selectedDate",
        JSON.stringify(this.selectedFilter)
      );
      sessionStorage.setItem("planName", plan.campaignName);
      sessionStorage.setItem("Objective", "Impressions");
      sessionStorage.setItem("planId", plan.unifiedCampaignId);
      this.$router.push("/solutions/unified-campaign");
    },
  },
  created() {
    sessionStorage.setItem("campaignType", this.$route.meta.type);
    this.getHealthMonitorList();
  },
};
</script>
<style>
.statics .item {
  width: 200px !important;
}
</style>
<style scoped>
.column-heading {
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: #222a37;
}

.column-data {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #222a37;
  font-weight: normal;
}

.table-top {
  padding: 12px;
}

.name-wrapper {
  padding: 24px;
}

.campagin-name {
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: #222a37;
}

.badge-wrapper {
  margin-top: 14px;
}

.created-on {
  margin-top: 32px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #9aafd4;
}

.overview-wrapper {
  border-left: 1px solid #dbdee4;
}

.slider-wrapper {
  border-bottom: 1px solid #dbdee4;
}

.statistics-wrapper {
  padding: 24px 0px;
  width: 875px;
  overflow-x: hidden;
}

.slider-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 31px;
  z-index: 9;
  margin: 8px 0px;
  font-size: 20px;
}

.slider-left {
  box-shadow: 22px 0px 14px #fff;
}

.slider-right {
  box-shadow: -22px 0px 14px #fff;
}

.channels {
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: #222a37;
  margin-left: 31px;
}

.btn-effect-outline {
  width: 208px;
  height: 60px;
  border-radius: 3px;
  border: solid 2px #050505;
  font-family: ProximaNovaBold;
  font-size: 18px;
  color: #222a37;
  background-color: transparent;
  margin: 9px;
}

.badge {
  padding: 8px;
  background-color: #deffe6;
  border: 1px solid #81f89e;
}

.item-right-line {
  position: absolute;
  height: 40px;
  width: 1px;
  background: #e8edf5;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

>>>.table>thead>tr>th {
  padding: 27px 26px;
}

>>>.table>tbody>tr {
  outline: none;
  box-shadow: 0 0 8px 0#c9ced5;
  border-top-width: 8px;
}

>>>.table>tbody>tr>td {
  padding: 0px;
}

>>>.table>tbody>.table-active>td {
  background-color: #eff5ff;
}

/* >>> .table > thead {
  box-shadow: 0 4px 7px #c9ced5;
} */
>>>.table>thead>tr>th {
  outline: none;
}

>>>.table> :not(:last-child)> :last-child>* {
  border: none;
}

>>>.table.b-table>thead>tr>[aria-sort]:not(.b-table-sort-icon-left),
.table.b-table>tfoot>tr>[aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right 1.5rem center;
}

.form-check-input {
  height: 24px;
  position: relative;
  top: 0px;
  margin: 0px;
  margin-right: 10px;
}

.form-check-input:checked {
  background-color: #222a37;
  border-color: #222a37;
}

.card {
  box-shadow: 0 0 8px 0 #c9ced5;
}

.image-wrapper-active {
  position: relative;
  background-color: #050505;
}

.tooltip-wrapper {
  width: 80px;
  height: 56px;
  background-color: #050505;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  clip-path: polygon(100% 0, 100% 53%, 50% 86%, 0 53%, 0 0);
  padding-top: 4px;
}

.search-wrapper {
  width: 318px;
  height: 60px;
  background-color: #eff5ff;
  display: flex;
  align-items: center;
  padding: 0;
}

input[type="text"] {
  border: none;
  border-color: #c6ccd6;
  margin-left: 0;
  background-color: #eff5ff;
  box-shadow: none;
  height: 60px;
  font-size: 16px;
}

input::placeholder {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #9aafd4;
}

input:focus {
  background-color: #eff5ff;
  outline: none;
  box-shadow: none;
}

.image {
  padding: 0 16px;
  width: 60px;
}

>>>.page-item.disabled .page-link {
  background-color: transparent;
}

>>>.page-link {
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  color: #9aafd4;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
}

>>>.page-link i {
  font-style: normal;
}

>>>.active>.page-link {
  background-color: transparent;
  border: 2px solid #050505;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  color: #222a37;
  box-shadow: none;
}

.no-items-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-items-description {
  opacity: 0.5;
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: #222a37;
  margin-top: 42px;
}

.date-picker {
  width: 150px;
  position: absolute;
  right: 12px;
}
</style>
